import React from 'react';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

const ITEMS = [
  {
    img: 'about-feature-img1.png',
    title: `對創新的積極嘗試：\nMIT台灣團隊設計研發製造`,
    description:
      '在英格蘭留學與遊歷的設計師，帶著源源不絕的實驗精神，不斷嘗試開發各樣型式的可能樣態，同時製作出各種奇特的本冊。「日常摺理 INFOLI 」是一個以手作裝幀為主題的開放空間，與其說這裡是一間專注印刷紙藝裝幀的工藝所，更貼切的或可以說成是一個知識交流學習的場域，包含紙張、技術、創作以及與各種未知的裝幀相遇。不論是對內部夥伴或是與外部客戶，隨時可以展開討論、知識、技能或經驗上的交流，有的只是一股不斷學習與修正前進的動能。',
  },
  {
    img: 'about-feature-img2.png',
    title: `向經驗的學習傳承：\n多樣化紙張裝幀款式`,
    description: `我們最具豐富經驗與絕妙手藝的師傅，有著52年的手作製本經驗，以其為核心，穩穩踏實地推進著手冊本的誕生！團隊也持續以各式的裝幀製程整合、多元材料實驗等打造作品。一件優秀的筆記手本，需要能夠乘載我們腦中的點子，不論是以書寫、手繪或是剪貼等的呈現，不會因受使用而質變;我們出品的筆記本，除了機器紙張裁切和穿線外，90%為手工製做，「日常摺理 INFOLI 」手本的本身其實也可以說如同是一件工藝品！`,
  },
];

function FeatureSection(props) {
  return (
    <Wrapper>
      <h3 style={{textAlign: 'center'}}>服務特點 · Feature</h3>
      <div className="container">
        {ITEMS.map((item, index) => (
          <ItemWrapper reverse={index % 2 !== 0}>
            <div className="bgimg">
              <StaticImage
                filename={item.img}
                extraStyle={{width: '100%', height: '100%'}}
              />
            </div>
            <div className="text-container">
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </div>
          </ItemWrapper>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;

  & > .container {
  }
`;

const ItemWrapper = styled.div`
  margin-bottom: 68px;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  flex-wrap: wrap;

  & > div {
    flex: 1 1 300px;
  }

  & > div.text-container {
    padding: 40px;
  }

  & > div > h4 {
    font-size: ${appConfig.fontSize.xl}px;
    white-space: pre-wrap;
    line-height: 1.5;
  }

  & > div > p {
    max-width: 450px;
    width: 100%;
    font-size: ${appConfig.fontSize.md}px;
    color: #777777;
    line-height: 2;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    & > div.text-container {
      padding: 20px;
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    margin-bottom: 40px;
  }
`;

export default FeatureSection;
