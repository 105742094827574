import React from 'react';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
import BlindingCarousel from './BlindingCarousel';
const appConfig = require('../../../src/data.json');

const data = [
  {icon: 'about_icon_車線膠裝.png', title: '車縫線膠裝'},
  {icon: 'about_icon_歐姆釘.png', title: '歐姆釘'},
  {icon: 'about_icon_膠裝.png', title: '膠裝'},
  {icon: 'about_icon_帳簿釘.png', title: '帳本簿釘裝'},
  {icon: 'about_icon_環裝.png', title: '單環裝/雙環裝'},
  {icon: 'about_icon_穿線膠裝.png', title: '穿線膠裝'},
  {icon: 'about_icon_糊頭裝.png', title: '糊頭裝'},
  {icon: 'about_icon_方背精裝.png', title: '方背精裝'},
  {icon: 'about_icon_圓背精裝.png', title: '圓背精裝'},
  {icon: 'about_icon_古書裝.png', title: '古書綁繩裝'},
  {icon: 'about_icon_經摺裝.png', title: '經摺裝'},
  {icon: 'about_icon_蝴蝶裝.png', title: '蝴蝶裝'},
];

function BlindingSection(props) {
  return (
    <Wrapper>
      <h3 className="title">摺理裝幀 · Blinding</h3>
      <div className="container">
        <p>
          透過條理層次分明的加工流程，集結經驗及細膩精確的手作工藝，逐步規劃設計及選用材料，加上反覆實體打樣測試
          ;
          提供使用者更多高質量手本裝幀方式的選擇，INFOLI同時也是一個實驗場，聚集所有裝幀工藝於此相互碰撞。
        </p>
        <div className="items">
          <BlindingCarousel data={data} />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1270px;
  width: 100%;

  & > h3.title {
    margin: 30px 10px;
    text-align: center;
  }

  & > div.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div.items {
      margin-bottom: 20px;
      width: 100%;
    }

    & > p {
      margin-bottom: 50px;
      max-width: 805px;
      width: 100%;
      text-align: center;
      font-size: ${appConfig.fontSize.md}px;
      line-height: 2;
    }
  }
`;

export default BlindingSection;
