import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {Row, Col, Button, Input, message} from 'antd';
import {Context} from '../../../src/AppContext';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

const Metadata = {
  form_section: {
    title: '聯絡我們',
    detail: `有任何問題與報價需求，歡迎填寫表單與我們聯絡！\r\n客服時間：${appConfig.information.service_time}\r\n門市地址：${appConfig.information.address}\r\n電子信箱：${appConfig.information.email}\r\n客服電話：${appConfig.information.tel}`,
  },
};

const CustomerFormFields = [
  {
    name: 'name',
    placeholder: '您的姓名',
    required: true,
  },
  {
    name: 'phone',
    placeholder: '手機號碼',
    required: true,
  },
  {
    name: 'tel',
    placeholder: '市話',
    required: false,
  },
  {
    name: 'telExtension',
    placeholder: '分機號碼',
    required: false,
  },
  {
    name: 'email',
    placeholder: '電子信箱',
    required: true,
    validator: (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value.toLowerCase());
    },
  },
];

const EmailFormFields = [
  {
    name: 'subject',
    placeholder: '信件主旨',
    required: true,
  },
  {
    name: 'content',
    placeholder: '信件內容',
    required: true,
  },
];

class ErrAboutPage extends Error {}

function ContactSection(props) {
  const app = useContext(Context);
  const [data, setData] = useState({
    name: '',
    phone: '',
    tel: '',
    telExtension: '',
    email: '',
    title: '',
    content: '',
  });

  const onFormSubmit = async () => {
    try {
      // basic validating form data
      let _blankFields = [];
      let _invalidFields = [];
      for (let f of [...CustomerFormFields, ...EmailFormFields]) {
        if (!data[f.name] && f.required) {
          _blankFields.push(f.placeholder);
        } else if (f.validator && !f.validator(data[f.name])) {
          _invalidFields.push(f.placeholder);
        }
      }
      if (_blankFields.length !== 0) {
        throw new ErrAboutPage(`請填入欄位：${_blankFields.join('、')}`);
      } else if (_invalidFields.length !== 0) {
        throw new ErrAboutPage(
          `請確認欄位：${_invalidFields.join('、')}的格式`,
        );
      }

      let formData = {
        subject: data.subject,
        message: data.content,
        name: data.name,
        phone: data.phone,
        local_phone: `${data.tel}#${data.telExtension}`,
        email: data.email,
      };

      await app.actions.contact(formData);

      message.success('聯絡表單已送出！');
    } catch (err) {
      if (err instanceof ErrAboutPage) {
        message.warning(err.message);
      } else {
        message.error('聯絡表單送出失敗！');
      }
    }
  };

  const onInputChange = (field) => (e) => {
    let value = e.target.value;
    setData((pre) => ({...pre, [field]: value}));
  };

  return (
    <Wrapper>
      <div className="section-context constraint form-section">
        <h3>{Metadata.form_section.title}</h3>
        <p>{Metadata.form_section.detail}</p>
        <div className="form-wrapper">
          <div className="form  rwd-need-margin-bottom">
            <img
              alt="contact-img"
              src="/images/about-contact.png"
              style={{width: '100%', height: '100%'}}
            />
          </div>
          <div className="form">
            {CustomerFormFields.map((field, idx) => {
              if (field.name === 'tel') {
                let telExtensionObj = CustomerFormFields.find(
                  (field) => field.name === 'telExtension',
                );

                return (
                  <Row gutter={8}>
                    <Col flex={2}>
                      <Input
                        key={idx}
                        value={data[field.name]}
                        onChange={onInputChange(field.name)}
                        placeholder={field.placeholder}
                      />
                    </Col>
                    <Col flex={1}>
                      <Input
                        key={idx + 1}
                        value={data[field.name]}
                        onChange={onInputChange(telExtensionObj.name)}
                        placeholder={telExtensionObj.placeholder}
                      />
                    </Col>
                  </Row>
                );
              }

              if (field.name === 'telExtension') {
                return null;
              }

              return (
                <Input
                  key={idx}
                  value={data[field.name]}
                  onChange={onInputChange(field.name)}
                  placeholder={field.placeholder}
                />
              );
            })}
            {EmailFormFields.map((field, idx) => {
              if (field.name === 'content') {
                return (
                  <Input.TextArea
                    key={idx}
                    style={{marginBottom: 0}}
                    value={data[field.name]}
                    placeholder={field.placeholder}
                    onChange={onInputChange(field.name)}
                  />
                );
              }
              return (
                <Input
                  key={idx}
                  value={data[field.name]}
                  onChange={onInputChange(field.name)}
                  placeholder={field.placeholder}
                />
              );
            })}
          </div>
        </div>

        <Button
          className="button hover-reverse-btn"
          type="primary"
          size="large"
          onClick={onFormSubmit}
          style={{
            flexGrow: 0,
            border: 0,
          }}>
          確認送出
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px 0px;
  background-color: #ede7dd;

  & .constraint {
    margin: 0 auto;
    max-width: 1270px;
    width: 100%;
  }

  & > .section-context {
    text-align: center;

    & p {
      margin-bottom: 40px;
      font-size: ${appConfig.fontSize.md}px;
      color: #717071;
      white-space: pre-wrap;
      line-height: 1.75;
    }

    @media screen and (max-width: 600px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  & .form-wrapper {
    margin-top: 40px;
    margin-bottom: 44px;
    display: flex;
    & .form {
      flex: 1;
      display: flex;
      flex-direction: column;
      & input,
      & textarea {
        border: 1px solid #cccccc;
        padding: 10px 20px;
        color: #5a5a5a;
        border-radius: 5px;
        margin-bottom: 20px;
        font-size: 16px;

        &:focus {
          outline: none;
        }
      }

      & textarea {
        flex: 1;
        resize: none;
      }

      &:last-child {
        margin-left: 40px;
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;

      & .form {
        &:last-child {
          margin-left: 0px;
        }
        & input,
        & textarea {
          margin-bottom: 10px;
        }
      }

      & .rwd-need-margin-bottom {
        margin-bottom: 20px;
      }
    }
  }

  & .hover-reverse-btn {
    background-color: ${appConfig.colors.main};
    color: white;
    &:hover {
      color: ${appConfig.colors.main};
      background-color: white;
    }
  }

  & .button {
    min-width: 200px;
    border-radius: 5px;
    display: inline-block;
  }

  & .error-hint {
    color: tomato;
    font-size: 12px;
    margin-top: 10px;
  }

  & .success-hint {
    color: #28a689;
    font-size: 12px;
    margin-top: 10px;
  }
`;

export default ContactSection;
