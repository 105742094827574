import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate} from 'gatsby';
const appConfig = require('../../../src/data.json');

const ITEMS = [
  {
    img: 'about-service-img1.jpg',
    name: '公規筆記本',
    desp: '集結眾多使用經驗及習慣，反覆規劃設計及實體測試，日常摺理找出了經驗值上最適用的手本規格。',
  },
  {
    img: 'about-service-img2.jpg',
    name: '客製化筆記本',
    desp: '各式使用紙材、裝幀方式、尺寸及頁數客戶皆可制訂！依據您的需求所生產，專為您而設。',
  },
  {
    img: 'about-service-img3.jpg',
    name: '設計師筆記本',
    desp: '集結優秀創作者及插畫筆記本品牌『小印紅鹿』合作，匠心獨運賦予每個手冊本新的使用可能！ ',
  },
];

function ShopServiceSection(props) {
  return (
    <Wrapper>
      <h3 style={{textAlign: 'center'}}>商店服務· Shop</h3>
      <div>
        <div className="items">
          {ITEMS.map((item) => (
            <ItemWrapper>
              <div
                style={{
                  marginBottom: 27,
                  backgroundImage: `url(/images/${item.img})`,
                  backgroundPosition: 'bottom',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: 300,
                }}
              />
              <h4>{item.name}</h4>
              <p>{item.desp}</p>
            </ItemWrapper>
          ))}
        </div>
        <Button
          type="primary"
          style={{margin: '0 auto'}}
          onClick={() => navigate('/products')}>
          前往全部商品
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 54px 20px 108px 20px;
  max-width: 1270px;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;

    & > .items {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    & > div {
      & > .items {
        flex-direction: column;
      }
    }
  }
`;

const ItemWrapper = styled.div`
  margin: 0px 20px 20px 20px;
  max-width: 360px;
  width: 100%;
  text-align: center;

  & > h4 {
    font-size: ${appConfig.fontSize.xl}px;
    color: ${appConfig.colors.main};
  }

  & > p {
    margin: 0px;
    min-height: 88px;
    font-size: ${appConfig.fontSize.md}px;
  }
`;

export default ShopServiceSection;
