import React from 'react';
import styled from 'styled-components';
import useDimension from '../../../src/hooks/use-dimension';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

const SECTION_MAX_WIDTH = 1120 + 40;

function HistorySection(props) {
  const {dimension} = useDimension();

  return (
    <Wrapper>
      <div className="container have-padding">
        <div className="have-translate">
          <StaticImage
            filename="about-history.jpg"
            extraStyle={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </div>
      <div className="container have-padding have-padding-rwd">
        <h3 className="title have-margin-bottom">
          紙本工藝創新團隊 · Our Team
        </h3>
        <p className="slogan"> - 台 灣 設 計 品 牌 - </p>
        <p className="slogan">- 職 人 工 藝 制 出 - </p>
        <p className="slogan">- 設 計 開 發 能 量 - </p>
        <p className="slogan">- 日 常 紀 錄 必 須 -</p>
        <p className="slogan have-margin-bottom">- 裝 幀 摺 紙 工 藝 -</p>
        <p className="description">
          作為欣賞優質文具者，我們想為大家打造一系列現代，低調且極富高質量的筆記本。「日常摺理」INFOLI更擅長專注以小批量優質生產，訂製品皆透過台灣師傅手工量身經典打造；作品上保持簡單，傾向讓美麗的材料為自己說話，與裝幀工藝對話。MIT品牌「日常摺理」INFOLI在品牌工藝所中，我們創造、定製並體驗手感所帶來的愉悅。
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${SECTION_MAX_WIDTH}px;
  width: 100%;
  background: #f2f2f2;
  display: flex;

  & > .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: left;
    min-height: 550px;

    & .have-padding {
      padding: 50px 30px;
    }

    & .have-margin-bottom {
      margin-bottom: 30px;
    }

    & > .have-translate {
      position: absolute;
      left: -60px;
      bottom: 80px;

      max-width: 620px;
      width: 100%;
      max-height: 620px;
      height: 100%;
    }

    & > .slogan {
      font-size: ${appConfig.fontSize.lg}px;
      color: #777777;
      line-height: 0.93;
    }

    & > .description {
      max-width: 443px;
      line-height: 2;
      font-size: ${appConfig.fontSize.md}px;
      color: #555555;
    }
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-direction: column;

    & > .container {
      min-height: 330px;

      &.have-padding {
        justify-content: flex-start;
        padding: 0px;
      }

      &.have-padding-rwd {
        padding: 40px;
      }

      & > .have-translate {
        position: static;
        left: 0px;
        bottom: 0px;

        max-width: 100%;
        max-height: 100%;
      }
    }
  }
`;

export default HistorySection;
