import React from 'react';
import styled from 'styled-components';
const appConfig = require('../../../src/data.json');

function AboutSection(props) {
  return (
    <Wrapper>
      <h3>Note & Life 讓我們一起紀錄生活的日常！</h3>
      <p>
        {`手本筆記為日常想法之乘載，而裝幀摺紙工藝就是成就筆記手本之底蘊。\nIn有融入、帶入、甚至是時尚意涵﹔folio則是最古老拉丁字源裡紙張變成書冊的最基礎字義，意指一葉片對摺成4頁的對開本，簡化後結合我們的品牌名稱 『融入日常的手冊本 』- Infoli。\n日常摺理 Infoli - 希望透過筆記手本工藝，帶領書寫人進入紙本的一紙一頁，透過筆尖與紙張，讓無形的想像力、抽象的思想躍然於紙張上，轉化為具體可見且更有溫度的符號形象，又或是透過手感書寫與塗畫，流暢地讓思緒與直覺交織，梳理我們日常生活中的感性與理性。`}
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 850px;
  width: 100%;

  & > p {
    margin: 0px;
    line-height: 2;
    font-size: ${appConfig.fontSize.md}px;
    color: ${appConfig.colors.text};
    white-space: pre-wrap;
  }
`;

export default AboutSection;
