import React from 'react';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
import AboutSection from './AboutSection';
import HistorySection from './HistorySection';
import BlindingSection from './BlindingSection';
import ShopServiceSection from './ShopServiceSection';
import FeatureSection from './FeatureSection';
import ProcessSection from './ProcessSection';
import ContactSection from './ContactSection';
import SocialMediaSection from '../LandingPage/SocialMediaSection';
const appConfig = require('../../../src/data.json');

function AboutPage(props) {
  return (
    <Wrapper>
      <div className="container banner">
        <StaticImage
          filename="about-banner.jpg"
          extraStyle={{width: '100%', height: '100%'}}
        />
      </div>

      <div className="container need-padding-more-bottom">
        <AboutSection />
      </div>

      <div className="container">
        <HistorySection />
      </div>

      <div className="container need-padding">
        <BlindingSection />
      </div>

      <div className="container">
        <ShopServiceSection />
      </div>

      <div className="container">
        <ProcessSection />
      </div>

      <div className="container need-padding">
        <FeatureSection />
      </div>

      <div className="container">
        <ContactSection />
      </div>

      <div className="container">
        <SocialMediaSection />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .container {
    width: 100%;
  }

  & .need-padding {
    padding: 60px 20px;
  }

  & .need-padding-more-bottom {
    padding: 60px 20px 150px;
  }

  & > .banner {
    background-color: #ececec;
    height: 580px;
    overflow: hidden;

    @media screen and (max-width: 600px) {
      height: 480px;
    }
  }

  & h3 {
    margin: 0px;
    margin-bottom: 40px;
    font-weight: normal;
    font-size: 22px;
    color: ${appConfig.colors.main};
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    & .need-padding {
      padding: 60px 20px;
    }
  }
`;

export default AboutPage;
