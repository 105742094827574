import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import {Button} from 'antd';
import {ArrowLeft, ArrowRight} from '@styled-icons/bootstrap';
import useDimension from '../../../src/hooks/use-dimension';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

const ITEM_SIZE = 165;

function BlindingCarousel(props) {
  const {dimension} = useDimension();
  const {data} = props;

  const restructureItems = (number, items) => {
    let itemsArray = [];

    for (let i = 0; i < items.length; i++) {
      if (itemsArray.length === 0) {
        itemsArray.push([items[i]]);
      } else if (itemsArray[itemsArray.length - 1].length === number) {
        itemsArray.push([items[i]]);
      } else {
        itemsArray[itemsArray.length - 1].push(items[i]);
      }
    }

    return itemsArray;
  };

  const renderItems = (items) => {
    if (dimension.innerWidth >= 1200) {
      return restructureItems(6, items);
    }

    if (
      dimension.innerWidth < 1200 &&
      dimension.innerWidth >= appConfig.breakpoints.lg
    ) {
      return restructureItems(4, items);
    }

    if (
      dimension.innerWidth < appConfig.breakpoints.lg &&
      dimension.innerWidth >= appConfig.breakpoints.sm
    ) {
      return restructureItems(2, items);
    }

    return items;
  };

  return (
    <Carousel
      height="200"
      style={{paddingLeft: 75, paddingRight: 75}}
      renderCenterLeftControls={({previousSlide}) => (
        <Button
          type="text"
          icon={<ArrowLeft size={24} />}
          onClick={previousSlide}
        />
      )}
      renderCenterRightControls={({nextSlide}) => (
        <Button
          type="text"
          ghost
          icon={<ArrowRight size={24} />}
          onClick={nextSlide}
        />
      )}
      renderBottomCenterControls={(props) => null}>
      {Array.isArray(renderItems(data)[0])
        ? renderItems(data).map((items) => (
            <Row>
              {items.map((item) => (
                <ItemWrapper>
                  <div>
                    <StaticImage
                      filename={item.icon}
                      extraStyle={{maxWidth: ITEM_SIZE, maxHeight: ITEM_SIZE}}
                    />
                  </div>
                  <label>{item.title}</label>
                </ItemWrapper>
              ))}
            </Row>
          ))
        : renderItems(data).map((item) => (
            <Row>
              <ItemWrapper>
                <div>
                  <StaticImage
                    filename={item.icon}
                    extraStyle={{width: ITEM_SIZE, height: ITEM_SIZE}}
                  />
                </div>
                <label>{item.title}</label>
              </ItemWrapper>
            </Row>
          ))}
    </Carousel>
  );
}

const Row = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    margin: 10px;
    width: ${ITEM_SIZE}px;
    height: ${ITEM_SIZE}px;
    border-radius: 50%;
    background-color: #f8f8f2;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > label {
    margin-top: 10px;
    text-align: center;
    font-weight: 400;
    font-size: ${appConfig.fontSize.lg}px;
    color: #000000;
  }
`;

export default BlindingCarousel;
