import React from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import {Button} from 'antd';
import {ArrowLeft, ArrowRight} from '@styled-icons/bootstrap';
import StaticImage from '../../../src/Components/StaticImage';
import useDimension from '../../../src/hooks/use-dimension';
const appConfig = require('../../../src/data.json');

const ITEMS = [
  {
    img: 'about-progress-img1.jpg',
    title: '本帖穿線：半世紀以上工業時代穿線機',
    description:
      '將每一單帖、單帖紙台按頁次配帖完成後，透過縫線車線機將連續數台車縫在一起，形成一冊冊有完整連續故事。',
  },
  {
    img: 'about-progress-img2.jpg',
    title: '裝幀魔法水：特殊比例調配書背膠',
    description:
      '因應不同的裝幀方式，膠的屬性使用亦不同，透過經驗混合不同膠、不同比例搭出不同的應用方式，調配的過程如施魔法般，讓裝幀應用提升更高檔次，可謂裝幀界的『神奇之液』。',
  },
  {
    img: 'about-progress-img3.jpg',
    title: '古老木質板手工收書',
    description:
      '透過光看就很有味道的老木件，上面滿佈且紀錄著各類書冊被定型的過程，在其中用手工刷上書背膠時的歲月積累的膠痕，更是保留手藝及惜物之下的結晶。',
  },
  {
    img: 'about-progress-img4.jpg',
    title: '外型強大但內含細膩：裁紙刀車',
    description:
      '積累專業50年經驗師傅的手工執行刀車操作，實作上可如細切蔥花般的精準裁切呀！精緻手本最後的完成，都必經過它的洗禮及修邊而誕生！',
  },
  {
    img: 'about-progress-img5.jpg',
    title: '摺紙：一張一張的紙',
    description:
      '日常摺理集結了極富經驗、心思細膩的資深師傅，製程上秉承精益求精的精神，與獨到的美感堅持，透機械的輔助手作製作。',
  },
  {
    img: 'about-progress-img6.jpg',
    title: '本帖車縫線：多種不同功能的車線台',
    description:
      '依設計需求提供粗線、細線、不同顏色線等車線材料與車線方式，其作用與本帖穿線類似，透過車線台讓單張與單張的摺紙，結合成美妙的單帖。',
  },
];

function ProcessSection(props) {
  const {dimension} = useDimension();
  const [slideIndex, setSlideIndex] = React.useState(0);

  return (
    <Wrapper>
      <Carousel
        autoplay={true}
        slideIndex={slideIndex}
        afterSlide={(index) => setSlideIndex(index)}
        renderCenterLeftControls={({previousSlide}) => null}
        renderCenterRightControls={({nextSlide}) => null}
        renderBottomCenterControls={({currentSlide, goToSlide, slideCount}) =>
          null
        }
        renderBottomLeftControls={({currentSlide, goToSlide, slideCount}) => {
          let indexes = Array.from(Array(slideCount).keys());
          return (
            <IndicatorWrapper>
              {indexes.map((index) => (
                <IndicatorLine
                  lenght={indexes.length}
                  selected={currentSlide === index}
                  onClick={() => goToSlide(index)}
                />
              ))}
            </IndicatorWrapper>
          );
        }}
        height={340}
        style={
          dimension.innerWidth > appConfig.breakpoints.lg
            ? {
                position: 'absolute',
                zIndex: 2,
                bottom: '50%',
                left: dimension.innerWidth > 1440 ? '10%' : '5%',
                transform: 'translate(0%, 50%)',
                maxWidth: 550,
              }
            : {}
        }>
        {ITEMS.map((item) => (
          <SlideWrapper>
            <div className="label">工序簡説</div>
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </SlideWrapper>
        ))}
      </Carousel>

      <div className="container">
        <div className="block-container" />
        <div className="img-container">
          <StaticImage
            filename={ITEMS[slideIndex].img}
            extraStyle={{width: '100%', height: '100%'}}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  max-width: 100%;
  position: relative;

  & > .container {
    display: flex;
  }

  & > .container > .block-container {
    flex: 1 0 150px;
  }

  & > .container > .img-container {
    flex: 2 0 300px;
  }

  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    & > .container > .block-container {
      display: none;
    }
  }
`;

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 30px;
  background-color: rgba(255, 255, 255, 0.6);

  & > .label {
    margin-bottom: 10px;
    font-size: ${appConfig.fontSize.lg}px;
    color: ${appConfig.colors.main};
  }

  & > h4 {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  & > p {
    font-size: ${appConfig.fontSize.md}px;
    color: #555555;
    line-height: 1.75;
  }

  @media screen and (min-width: 1440px) {
    padding: 60px 60px;

    & > .label {
      margin-bottom: 15px;
    }
    & > h4 {
      margin-bottom: 20px;
    }
  }
`;

const IndicatorWrapper = styled.div`
  max-width: 550px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 30px;

  @media screen and (min-width: 1440px) {
    padding: 30px 60px;
  }
`;

const IndicatorLine = styled.div`
  cursor: pointer;
  margin: 5px 5px 20px 5px;
  flex: 1;
  width: calc(440px / 6);
  height: 5px;
  background-color: ${(props) =>
    props.selected ? `${appConfig.colors.third}` : '#ccc'};

  @media screen and (max-width: ${appConfig.breakpoints.sm}px) {
    width: 40px;
  }
`;

export default ProcessSection;
